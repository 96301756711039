<template lang="pug">
el-dialog(
  v-model="showDialog"
  :align-center="true"
  @close="showDialog = false"
)
  div(v-if="results.length")
    el-row(justify="end")
      el-button(plain @click="copyToClipboard") Скопировать в формате JSON
    br
    el-table(:data="results")
      el-table-column(label="Оригинал" v-slot="scope")
        el-link(:href="scope.row.file.url") {{ `${origin}${scope.row.file.url}` }}
      el-table-column(label="Разметка" v-slot="scope")
        el-link(:href="scope.row.result?.url") {{ scope.row.result ? `${origin}${scope.row.result?.url}` : '' }}
  el-empty(v-else)

el-card
  el-form(:inline="true")
    el-form-item(style="width: 15%")
      el-select(placeholder="Тип" v-model="params.type" clearable @change="list")
          el-option(
            placeholder="Выберите тип"
            v-for="item in tasksStore.types"
            :label="item.name"
            :value="item.key"
          )
    el-form-item(style="width: 15%")
      el-select(placeholder="Статус" v-model="params.status" clearable @change="list")
          el-option(
            v-for="label, value in statuses.entryStatusText"
            :label="label"
            :value="value"
          )
    el-form-item(style="width: 15%")
      el-select(placeholder="Все" v-model="params.defected" clearable @change="list")
          el-option(
            v-for="value, label in defectedOptions"
            :label="label"
            :value="value"
          )
    el-form-item(style="width: 25%")
      el-date-picker(
        type="daterange"
        v-model="dateRange"
        format="DD.MM.YYYY"
        value-format="x"
        range-separator="—"
        clearable
        @change="applyDateRange"
      )
    el-form-item
      el-button(v-if="selectedRows.length > 0" plain @click="getResults") Показать ссылки

el-card
  el-table(
      ref="tableRef"
      :data="tasksStore.entries"
      v-loading="loading"
      @selection-change="handleSelectionChange"
      @row-click="(row) => tableRef?.toggleRowSelection(row, undefined)"
  )
    el-table-column(type="selection")
    el-table-column(prop="type.name" label="Тип" header-align="center" align="center")
    el-table-column(prop="status" label="Статус" v-slot="scope: { row: TaskEntry }" header-align="center" align="center")
      el-text(
        :type="statuses.textTypeByEntryStatus[scope.row.status]"
      ) {{ statuses.entryStatusText[scope.row.status] }}
    el-table-column(prop="performer" label="Исполнитель" header-align="center" align="center")
    el-table-column(prop="createdBy" label="Создан" width="180" header-align="center" align="center")

  el-pagination.center(
      layout="total, prev, pager, next, sizes"
      :total="tasksStore.total || 0"
      v-model:current-page="params.page"
      v-model:page-size="params.perPage"
      :page-sizes="[100, 300, 500, 1000]"
      @current-change="list"
      @size-change="list"
  )
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { ElTable, ElMessage } from 'element-plus'
import { useTasksV2Store } from '@/store/tasks_v2'
import type { TaskEntry, EntriesListRequest } from '@/models/tasks_v2'
import type { _File } from '@/models/common'
import * as statuses from '@/utils/statuses'

const params = ref<EntriesListRequest>({
  type: undefined,
  status: undefined,
  defected: false,
  since: undefined,
  to: undefined,
  page: 1,
  perPage: 100,
})
const loading = ref(false)
const showDialog = ref(false)
const dateRange = ref<(number | undefined)[] >([undefined, undefined])
const tableRef = ref<InstanceType<typeof ElTable>>()
const selectedRows = ref<TaskEntry[]>([])
const results = ref<{ file: _File, result?: _File }[]>([])
const tasksStore = useTasksV2Store()
const origin = window.location.origin

const defectedOptions = {
  'Бракованные': true,
  'Без брака': false,
}

const handleSelectionChange = (entries: TaskEntry[]) => {
  selectedRows.value = entries
}

const applyDateRange = () => {
  params.value.since = dateRange.value[0]
  params.value.to = dateRange.value[1]
  list()
}

const getResults = () => {
  results.value = selectedRows.value.map(e => {
    return { file: e.file, result: e.result }
  })
  showDialog.value = true
}

const copyToClipboard = () => {
  const data: any[] = []

  selectedRows.value.forEach(e => {
    data.push({
      studyUrl: `${origin}${e.file.url}`,
      resultUrl: e.result ? `${origin}${e.result.url}` : null,
      performer: e.performer,
      status: statuses.entryStatusText[e.status],
      type: e.type.name,
      taskUid: e.taskUid,
      taskEntryUid: e.uid,
      performerComment: e.performerComment,
      validatorComment: e.validatorComment,
      validatedBy: e.validatedBy ?? null,
    })
  })

  try {
    navigator.clipboard.writeText(JSON.stringify(data, null, 2))
      .then(() => ElMessage.success('Скопировано'))
  } catch (e) {
    console.log(e)
  }
}

const list = () => {
  loading.value = true
  tasksStore.getEntries(params.value)
    .finally(() => {
      loading.value = false
    })
}

onMounted(() => {
  tasksStore.getTaskTypes()
  list()
})
</script>
