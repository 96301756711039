<template lang="pug">
el-space(direction="vertical")
  el-radio-group(v-model="metric")
    el-radio-button(
      v-for="(v, k) in availableMetrics"
      :key="k"
      :label="k"
      @change="emit('metric-changed', k)"
    ) {{ v }}

  el-radio-group(v-model="option")
    el-radio-button(
      v-for="(v, k) in STAT_OPTION"
      :key="k"
      :label="k"
      @change="emit('option-changed', k)"
    ) {{ v }}

  el-radio-group(v-model="period")
    el-radio-button(
      v-for="(v, k) in STAT_PERIOD"
      :key="k"
      :label="k"
      @change="emit('period-changed', k)"
    ) {{ v }}
</template>

<script setup lang="ts">
import { useAuthStore } from '@/store/auth'
import { STAT_OPTION, STAT_PERIOD, STAT_METRIC } from '@/models/stat'
import {
  STAT_METRIC_COUNT,
  STAT_METRIC_DECLINED_PERCENT,
  STAT_METRIC_SUPPLEMENTED_PERCENT,
  STAT_METRIC_VALIDATIONS_COUNT,
} from '@/models/stat';
import type { Option, Period, Metric } from '@/models/stat'

const props = defineProps<{
  option: Option
  period: Period
  metric: Metric
}>()

const authStore = useAuthStore()

const availableMetrics = computed(() => {
  const metrics: any = { [STAT_METRIC_COUNT]: STAT_METRIC[STAT_METRIC_COUNT] }

  if (authStore.isValidator) {
    metrics[STAT_METRIC_VALIDATIONS_COUNT] = STAT_METRIC[STAT_METRIC_VALIDATIONS_COUNT]
  }

  metrics[STAT_METRIC_DECLINED_PERCENT] = STAT_METRIC[STAT_METRIC_DECLINED_PERCENT]
  metrics[STAT_METRIC_SUPPLEMENTED_PERCENT] = STAT_METRIC[STAT_METRIC_SUPPLEMENTED_PERCENT]

  return metrics
})

const option = ref(props.option)
const period = ref(props.period)
const metric = ref(props.metric)

const emit = defineEmits(['option-changed', 'period-changed', 'metric-changed'])
</script>
