<template lang="pug">
el-form(ref="formRef" :model="formModel" :rules="rules")
    div.create-form-row
        el-form-item(prop="type" style="width: 15%")
            el-select(placeholder="Тип" v-model="formModel.type" clearable)
                el-option(v-for="_type in tasksStore.types" :label="_type.name" :value="_type.key" align="center")

        el-form-item(prop="priority" style="width: 20%")
            el-select(placeholder="Приоритет" v-model="formModel.priority")
                el-option(v-for="(label, value) in taskPriorities" :label="label" :value="value" align="center")

        el-form-item(prop="performers" style="width: 30%")
            el-select(
                placeholder="Исполнители"
                v-model="formModel.performers"
                multiple collapse-tags collapse-tags-tooltip
            )
                el-option(
                    v-for="user in tasksStore.users"
                    :label="`${user.firstName} ${user.lastName}`" :value="user.username"
                    align="center"
                )

    div.create-form-row
        el-form-item(v-model="formModel.file" prop="file")
            el-upload(
                drag
                ref="uploadRef"
                :limit="1"
                :on-exceed="handleExceed"
                :multiple="false"
                :auto-upload="false"
                v-model:file-list="files"
            )
                UploadIcon(style="height:3em;")
                div(class="el-upload__text")
                    span Перетащите файл или&nbsp;
                    em нажмите для загрузки

    el-form-item(prop="description")
        el-input(placeholder="Описание" v-model="formModel.description" type="textarea" rows="5" resize="none")

    el-row(justify="end")
        el-button(@click="handleCreateButtonClick(formRef)") Создать

el-progress(
    v-if="uploadingInProgress"
    class="el-progress-upload-file"
    type="circle"
    :style="`z-index: ${elProgressZIndex};`"
    :width="100"
    :percentage="uploadPercent"
    :stroke-width="10"
    :status="uploadStatus"
)
</template>

<script setup lang="ts">
import { ref, reactive, computed } from 'vue'
import { Upload as UploadIcon } from '@element-plus/icons-vue'
import { ElMessage, ElLoading } from 'element-plus'
import type { UploadProps, UploadRawFile, UploadUserFile, FormInstance } from 'element-plus'

import { useTasksV2Store } from '@/store/tasks_v2'


const tasksStore = useTasksV2Store()

interface FormModel {
    type: string,
    performers: string[],
    priority?: number,
    file?: File,
    description?: string,
}

const formModel = reactive<FormModel>({
    type: '',
    performers: [],
    priority: undefined,
    file: undefined,
    description: undefined,
})

const formRef = ref<FormInstance>()
const files = ref<UploadUserFile[]>()
const uploadRef: any = ref(null)
const uploadPercent = ref(0)
const uploadingInProgress = ref(false)
const elProgressZIndex = ref(2010)

const emit = defineEmits(['created'])

const uploadStatus = computed((): 'success' | '' => {
    return uploadPercent.value === 100 ? 'success' : ''
})

const taskPriorities = {
    10: '🟢',
    20: '🟡',
    30: '🔴',
}

const rules = {
    type: [{
        required: true,
        message: 'Выберите тип задачи'
    }],
    file: [{
        required: true,
        validator: function(rule: any, value: any, callback: any) {
            if (!files.value?.length) {
                callback(new Error('Выберите файл для загрузки'))
            }
            callback()
        },
    }],
    prioroty: [{required: false}],
    performers: [{required: false}],
    description: [{required: false}],
}


const handleExceed: UploadProps['onExceed'] = (files) => {
    uploadRef.value!.clearFiles()
    uploadRef.value!.handleStart(files[0] as UploadRawFile)
}

const handleCreateButtonClick = async (form: FormInstance | undefined) => {
    if (!form) {
        return
    }

    await form.validate((valid, _) => {
        if (valid) {
            submit()
        }
    })
}

const submit = () => {
    const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        svgViewBox: '0, 0, 0, 0',
    })

    elProgressZIndex.value = Number(loading.$el.style.zIndex) + 1
    uploadingInProgress.value = true

    tasksStore.createTask({
        type: formModel.type,
        priority: formModel.priority,
        performers: formModel.performers,
        file: files.value!.at(0)?.raw as File,
        description: formModel.description,
        progressCallback: (percent) => uploadPercent.value = percent,
    })
        .then(() => {
            ElMessage.success('Задача создана')

            uploadRef.value!.clearFiles()
            formModel.type = ''
            formModel.performers = []
            formModel.priority = undefined
            formModel.file = undefined
            formModel.description = undefined

            emit('created')
        })
        .finally(() => {
            uploadingInProgress.value = false
            loading.close()
            uploadPercent.value = 0
        })
}
</script>

<style scoped>
.el-progress-upload-file {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -50px;
    margin-top: -50px;
}

.create-form-row {
    display: flex;
    gap: 5%;
    justify-content: center;
}
</style>
